import {useRef, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import lottie from "lottie-web";
import Shake from "reshake/dist/Shake";
import {Link} from "react-router-dom";

import cloud_divider_json from "../lotties/cloud_divider.json"
import cloud1_json from "../lotties/cloud1.json"
import cloud2_json from "../lotties/cloud2.json"
import dobby1_json from "../lotties/dobby1.json"
import dobby2_json from "../lotties/dobby2.json"
import plants_json from "../lotties/plants.json"
import plants_mobile_json from "../lotties/plants_mobile.json"
import lava_json from "../lotties/lava.json"
import planet_json from "../lotties/planet.json"
import planet_mobile_json from "../lotties/planet_mobile.json"
import portal_json from "../lotties/portal.json"

const Home = () => {
    // page content
    const collection = useRef()
    const handleLearnMore = () => {
        const {top: t, left: l} = collection.current.getBoundingClientRect()
        if (window.innerWidth > 1000) {
            window.scrollTo({top: t + window.scrollY - 200, behavior: 'smooth'})
        } else {
            window.scrollTo({top: t + window.scrollY + 100, behavior: 'smooth'})
        }
    }
    const cloud1 = useRef(null)
    const cloud2 = useRef(null)
    const cloud_divider = useRef(null)
    const cloud_divider2 = useRef(null)
    const cloud_divider_bottom1 = useRef(null)
    const cloud_divider_bottom2 = useRef(null)
    const dobby1 = useRef(null)
    const dobby2 = useRef(null)
    const plants = useRef(null)
    const plants_mobile = useRef(null)
    const lava = useRef(null)
    const planet = useRef(null)
    const planet_mobile = useRef(null)
    const portal = useRef(null)
    const portal_mobile = useRef(null)
    useEffect(() => {
        lottie.loadAnimation({
            container: cloud_divider.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: cloud_divider_json
        });
        lottie.loadAnimation({
            container: cloud_divider2.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: cloud_divider_json
        });
        lottie.loadAnimation({
            container: cloud_divider_bottom1.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: cloud_divider_json
        });
        lottie.loadAnimation({
            container: cloud_divider_bottom2.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: cloud_divider_json
        });
        lottie.loadAnimation({
            container: cloud1.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: cloud1_json
        });
        lottie.loadAnimation({
            container: cloud2.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: cloud2_json
        });
        lottie.loadAnimation({
            container: dobby1.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: dobby1_json
        });
        lottie.loadAnimation({
            container: dobby2.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: dobby2_json
        });
        lottie.loadAnimation({
            container: lava.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: lava_json
        });
        lottie.loadAnimation({
            container: plants.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: plants_json
        });
        lottie.loadAnimation({
            container: plants_mobile.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: plants_mobile_json
        }).frameRate = 25;
        lottie.loadAnimation({
            container: planet.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: planet_json
        });
        lottie.loadAnimation({
            container: planet_mobile.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: planet_mobile_json
        }).frameRate = 25;
        lottie.loadAnimation({
            container: portal.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: portal_json
        });
        lottie.loadAnimation({
            container: portal_mobile.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: portal_json
        }).frameRate = 25;
    }, []);

    const getEmbedYoutubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
    
        return (match && match[2].length === 11)
          ? match[2]
          : null;
    }

    const getEmbedYoutubeUrl = (url) => {
        return 'https://www.youtube.com/embed/' + getEmbedYoutubeId(url) + "?vq=highres";
    }

    return (
        <section id="home">
            <div className='dobby-landing'>

                <div className='home-body'>

                    <div className='text-center position-relative'>
                        <div ref={cloud1} className='cloud-left'></div>
                        <div ref={cloud2} className='cloud-right'></div>
                        {/* <img src='graphics/Hero_Clouds_Left.svg' className='cloud-left' /> */}
                        <img src='graphics/Logo.svg' className='large-logo position-relative'/>
                        {/* <img src='graphics/Hero_Clouds_Right.svg' className='cloud-right' /> */}
                    </div>
                    <div className='text-center'>
                        {/* <img src='graphics/dobby.webp' className='dobby' /> */}
                        <div ref={dobby1} className="dobby"></div>
                    </div>
                    <div className='position-relative'>
                        {/* <img src='graphics/Clouds_Divider.svg' className='cloud-bottom' /> */}
                        <div ref={cloud_divider} className='cloud-bottom'></div>
                        <div className='header-description-field'>
                            <div className='text-center header-description'>
                                <p className='text-sans2'><span className='text-bold'>Dobbys</span> is a family-friendly
                                    NFT project consisting of programmatically, <br/> randomly generated dinosaurs
                                    living on the <span className='text-bold'>Ethereum blockchain</span>.</p>
                                {/*<p className=' text-sans2'><span className='text-bold'>Our mission </span>is to give*/}
                                {/*    holders exclusive access to experiences, merchandise, community-driven events and to*/}
                                {/*    allow holders to lead the project to success while earning <span*/}
                                {/*        className='text-bold'>passive income</span>.</p>*/}
                                {/*<div className='w-100 join-area'>*/}
                                {/*    /!*<div className='learn-more-btn text-brother text-white ' onClick={handleLearnMore}>LEARN MORE</div>*!/*/}
                                {/*    <Shake*/}
                                {/*        h={2}*/}
                                {/*        v={1}*/}
                                {/*        r={1}*/}
                                {/*        dur={1500}*/}
                                {/*        int={4}*/}
                                {/*        max={51}*/}
                                {/*        fixed={true}*/}
                                {/*        fixedStop={false}*/}
                                {/*        freez={false}>*/}
                                {/*        <a href="https://discord.com/invite/5vXP2wqywp"*/}
                                {/*           className="join-btn text-brother" id="tagclick">*/}
                                {/*            <i className='fa-brands fa-discord join-discord'></i>*/}
                                {/*            Join Community*/}
                                {/*        </a>*/}
                                {/*    </Shake>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                        {/* <img src='graphics/Hero_Clouds_Bottom.svg' className='cloud-divider-bottom'></img> */}
                        <div ref={cloud_divider_bottom1} className='cloud-divider-bottom'></div>
                    </div>

                </div>
                <div className='who-dobbys-are'>
                    <div className='d-flex justify-content-center pt-3 pb-3 dobby-video-wrapper'>
                        <iframe
                            src={getEmbedYoutubeUrl('https://www.youtube.com/watch?v=Ipv1WcNcfWs')}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                        />
                    </div>
                    <div className='d-flex justify-content-center pt-5 who-dobby'>
                        <div className='text-sans2 who-dobby-des mb-5'>
                            <img src='graphics/Headline_Who_Dobbys_Are.svg' width={350}
                                 className='who-dobbys-headline mb-3'/>
                            {/* <h1 className='dobby-title1 text-brother'>Who dobbys are ?</h1> */}
                            <p>Thousands of years ago, in a galaxy a million miles away from Planet Earth, there was a
                                collection of <span className='text-bold'>tiny planets.</span> Despite their
                                differences, these planets lived in perfect
                                harmony and peace, working together to thrive as one. <span className='text-bold'>That was until the Galactic War...</span>
                            </p>
                            <p>One evening, on what seemed like a perfectly ordinary day, an evil force entered the
                                galaxy, <span
                                    className='text-bold'>invading and conquering the planets one by one. </span> While
                                they fought hard to defend their homes, the planets knew they could only hold
                                the evil forces back for so long... so they came up with a plan to survive.</p>
                            <p>They knew that the planets in their galaxy would be destroyed one by one by these evil
                                forces and escape was impossible. If thousands of ships were spotted flying off into
                                space, the invaders would surely follow, seek, and destroy. Whatever plan they came up
                                with, it would have to be quiet, covert... sneaky.
                                That's when it hit them.<br/>
                                <span className='text-bold'>Perhaps each planet would have to make a grand sacrifice to save just one...</span>
                            </p>
                        </div>
                    </div>


                </div>
                <div className='dobby-world'>

                    <div className='text-center w-100 dobby-world-body'>
                        <div className='plants'>
                            <div ref={plants} className='plants-portal'></div>
                            <div ref={plants_mobile} className='plants-portal-mobile'></div>
                            <div ref={dobby2} className='dobby-img'></div>
                            <div ref={planet} className='planet-img'></div>
                            <div ref={portal} className='portal-img'></div>
                            <div ref={portal_mobile} className='portal-mobile'></div>
                            {/* <img src='graphics/Plants_Portal.svg' className='plants-portal' /> */}
                            {/* <img src='graphics/mobile/plants and top portal-cropped.webp' className='plants-portal-mobile' /> */}
                            {/* <img src='graphics/dobby_description.webp' className='dobby-img' /> */}
                            {/* <img src='graphics/Planet_Portal.svg' className='planet-img' /> */}
                        </div>
                    </div>


                    <img src='graphics/Stars.svg' className='stars'/>
                    <div className='dobby-world-des'>
                        <img src='graphics/Headline_A_New_Dobby_World.svg' width={350}
                             className='who-dobbys-headline mb-4'/>
                        <p className='text-white text-sans2'>Each planet chose one being to represent them, loaded them
                            into a teleporter, and sent them to a brand-new world in a secret location far from the
                            evil forces threateningto render them extinct. Different creatures from different home
                            planets, all coming together to form one new colony dubbed <span className='text-bold'>The Dobbys.</span>
                        </p>
                        <p className='text-white text-sans2'>The Dobby came in all different shapes and sizes due to
                            their differing home planets, but they were determined to <span className='text-bold'>make Dobbyland a new safe haven for them all. </span>
                            No matter what they looked like, no matter what they wore, no matter where they came from,
                            Dobbyland was a fair, equal and kind society for everyone.</p>
                        <p className='text-white text-sans2'>Over the years, The Dobbys harvested from a nearby forest
                            and built houses from the ground up. They mined rock and are to make tools. They gathered
                            fruit, berries, and mushrooms to sustain themselves, while pumping water from the great lake
                            in the
                            <span className='text-bold'> centre of Dobbyland.</span>
                        </p>
                    </div>
                </div>
                <div className='dobby-collection'>

                    <div className='text-center w-100 dobby-collection-body'>
                        <div className='planets'>
                            <div>
                                <div ref={cloud_divider2} className='cloud-bottom2'></div>
                                <div className='about-dobbyland-whiteboard'></div>
                                <div ref={cloud_divider_bottom2} className='cloud-divider-bottom'></div>
                                {/* <img src='graphics/Hero_Clouds_Bottom.svg' className='cloud-divider-bottom'></img> */}
                            </div>
                            {/* <img src='graphics/Clouds_Divider.svg' className='clouds' /> */}
                            {/* <img src='graphics/Planet_Portal.svg' className='planet-img-mobile' /> */}
                            <div ref={planet_mobile} className='planet-img-mobile'></div>
                            <div className='dobbyland text-sans2'>
                                <img src='graphics/Headline_About_Dobbyland.svg' width={350}
                                     className='about-dobbyland mb-4'/>
                                <p>Before long, they had a fully-functioning society on their new home planet. They had
                                    shops and restaurants and markets. They had libraries and cinemas and supermarkets.
                                    They had skyscrapers, cars, aeroplanes, and boats.
                                </p>
                                <p><span className='text-bold'>Within a few years,</span>they had explored every single
                                    nook and cranny of their new planet, spreading out as far as the eye could see.</p>
                                <p>With every passing year, <span
                                    className='text-bold'>more and more Dobbys are born,</span> with more and more
                                    unique appearances - each having its own set of clothes, mouth, accessories, eyes,
                                    spikes, hat and pet.</p>
                                <p><span className='text-bold'>Dobbyland was a beautiful and safe haven for all that lived there... so why not come and visit today?</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='collection-container w-100 d-flex justify-content-center align-items-center'>
                        <div className='d-flex collection-show'>
                            <div className='typical-collection'>
                                <img src='graphics/all dobbys banner/04.svg' width={150} height={150}
                                     className="collection-img typical1"></img>
                                <img src='graphics/all dobbys banner/05.svg' width={170} height={170}
                                     className="collection-img typical2"></img>
                                <img src='graphics/all dobbys banner/10.svg' width={200} height={200}
                                     className="collection-img typical3"></img>
                            </div>
                            <div className='collection-des' ref={collection}>
                                <img src='graphics/Headline_Collection.svg' width={450}
                                     className='dobby-collection-headline mb-4'/>
                                <img src='graphics/mobile/Headline_Collection_mobile.svg' width={450}
                                     className='who-dobbys-headline-mobile mb-4'/>
                                <p className='text-sans2'>Dobbys are a <span
                                    className='text-bold'>collection of NFTs </span>(non-fungible tokens) that are made
                                    up of hundreds of exciting visual traits.</p>
                                <p className='text-sans2'>The future of Dobbys solely depends on the success of the <span
                                    className='text-bold'>community</span>, which will be the driving factor on the whole journey!</p>
                                {/*<p className='text-sans2'>The Dobbyland is ever-expanding and new experiences like*/}
                                {/*    Doobcoin, Vision Dobbys or Evolution Dobbys are <span className='text-bold'>only available to collectors</span>*/}
                                {/*</p>*/}
                                {/*<p className='text-sans2'>Being an OG pays off: Holders of the first collection will be*/}
                                {/*    granted <span className='text-bold'>100% of secondary royalties of this and future collections</span>.*/}
                                {/*</p>*/}
                                {/*<p className='text-sans2'>While the universe expands, our brand grows, and collectors*/}
                                {/*    can expect exclusive access to the latest products, merchandise and events through*/}
                                {/*    ownership.</p>*/}
                                {/*<p className='text-sans2'><span className='text-bold'>Owning a Dobby allows you to vote on community-driven features, products and events.</span>*/}
                                {/*</p>*/}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center w-100 mt-2 collection-row'>
                        <div className='d-flex justify-content-between align-items-center collection-type'>
                            <img src='graphics/all dobbys banner/01.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/02.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/03.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/04.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/05.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/06.svg' width={110} height={110}
                                 className="collection-img"></img>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center w-100 mt-4 collection-row'>
                        <div className='d-flex justify-content-between align-items-center collection-type'>
                            <img src='graphics/all dobbys banner/07.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/08.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/09.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/10.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/11.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/12.svg' width={110} height={110}
                                 className="collection-img"></img>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center w-100 mt-4 collection-row'
                         style={{marginBottom: 100}}>
                        <div className='d-flex justify-content-between align-items-center collection-type'>
                            <img src='graphics/all dobbys banner/13.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/14.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/15.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/16.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/17.svg' width={110} height={110}
                                 className="collection-img"></img>
                            <img src='graphics/all dobbys banner/18.svg' width={110} height={110}
                                 className="collection-img"></img>
                        </div>
                    </div>
                    {/*<div className='d-flex justify-content-center'>*/}
                    {/*  <div className='wallet'>*/}
                    {/*    <h1 className='text-brother text-center wallet-title mb-5'>COMMUNITY WALLET</h1>*/}
                    {/*    <div className='wallet-info'>*/}
                    {/*      <div className='estimate-value'>*/}
                    {/*        <p className='text-sans2 esti-val'>Estimated Value</p>*/}
                    {/*        <div className='text-end'>*/}
                    {/*          <p className='text-brother value'>coming soon</p>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*      <div className='wallet-des'>*/}
                    {/*        <p className='text-sans2'>The Dobby Wallet is a shared fund among all Dobby Holders in the Dobby Community.</p>*/}
                    {/*        <p className='text-sans2'>The majority of NFT sales are used to fund other community projects</p>*/}
                    {/*        <p className='text-sans2'>For example, community-events, the Doobcoin, Vision Dobbys or Evolution Dobbys.</p>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    <div className='w-100 join-area mt-3'>*/}
                    {/*        /!*<div className='learn-more-btn text-brother text-white ' onClick={handleLearnMore}>LEARN MORE</div>*!/*/}
                    {/*        <Shake*/}
                    {/*            h={2}*/}
                    {/*            v={1}*/}
                    {/*            r={1}*/}
                    {/*            dur={1500}*/}
                    {/*            int={4}*/}
                    {/*            max={51}*/}
                    {/*            fixed={true}*/}
                    {/*            fixedStop={false}*/}
                    {/*            freez={false}>*/}
                    {/*          <a href="https://discord.com/invite/5vXP2wqywp" className="join-btn text-brother" id="tagclick">*/}
                    {/*            <i className='fa-brands fa-discord join-discord'></i>*/}
                    {/*            Join Community*/}
                    {/*          </a>*/}
                    {/*        </Shake>*/}
                    {/*      </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {/*<div className='d-flex justify-content-center align-items-center w-100' style={{marginBottom: 100}}>*/}
                    {/*    <Link to="/roadmap" className="check-btn text-brother">*/}

                    {/*        CHECK ROADMAP*/}
                    {/*    </Link>*/}
                    {/*</div>*/}

                </div>

                {/*<div className='dobby-team'>*/}
                {/*    <div className='team'>*/}
                {/*        <img src='graphics/Green_Hill.svg' className='green-hill'/>*/}
                {/*        /!* <img src='graphics/Lava_Divider.svg' className='lava-divider' /> *!/*/}
                {/*        <div className='lava-divider' ref={lava}></div>*/}
                {/*    </div>*/}
                {/*    <div className='dobby-team-main'>*/}
                {/*        <div className='team-member'>*/}
                {/*            <div className='text-center team-info pb-5'>*/}
                {/*                <h1 className='team-title text-brother mb-5'>TEAM</h1>*/}

                {/*                <Row className='members'>*/}
                {/*                    <Col xs={6} md={3}>*/}
                {/*                        <img src='graphics/team/nftsimon.webp' width={200} height={200}*/}
                {/*                             className="member-img member1 mb-3"/>*/}
                {/*                        <h1 className='member-title text-brother mb-2'>NFTSIMON</h1>*/}
                {/*                        <h5 className='text-sans2 member-subtitle'>Founder</h5>*/}
                {/*                    </Col>*/}
                {/*                    <Col xs={6} md={3}>*/}
                {/*                        <img src='graphics/team/nftjana_v2.webp' width={200} height={200}*/}
                {/*                             className="member-img member2 mb-3"></img>*/}
                {/*                        <h1 className='member-title text-brother mb-2'>NFTJANA</h1>*/}
                {/*                        <h5 className='text-sans2 member-subtitle'>Co-Founder</h5>*/}
                {/*                    </Col>*/}
                {/*                    <Col xs={6} md={3}>*/}
                {/*                        <img src='graphics/team/SealCoder.webp' width={200} height={200}*/}
                {/*                             className="member-img member3 mb-3"></img>*/}
                {/*                        <h1 className='member-title text-brother mb-2'>SealCoder</h1>*/}
                {/*                        <h5 className='text-sans2 member-subtitle'>Web3 Dev</h5>*/}
                {/*                    </Col>*/}
                {/*                    <Col xs={6} md={3}>*/}
                {/*                        <img src='graphics/team/cinnamongod.webp' width={200} height={200}*/}
                {/*                             className="member-img member3 mb-3"></img>*/}
                {/*                        <h1 className='member-title text-brother mb-2'>CinnamonRollGod </h1>*/}
                {/*                        <h5 className='text-sans2 member-subtitle'>Strategy Project Manager</h5>*/}
                {/*                    </Col>*/}
                {/*                </Row>*/}

                {/*                <div className='w-100 join-area mt-3'>*/}
                {/*                    <Shake*/}
                {/*                        h={2}*/}
                {/*                        v={1}*/}
                {/*                        r={1}*/}
                {/*                        dur={1500}*/}
                {/*                        int={4}*/}
                {/*                        max={51}*/}
                {/*                        fixed={true}*/}
                {/*                        fixedStop={false}*/}
                {/*                        freez={false}>*/}
                {/*                        <a href="https://discord.com/invite/5vXP2wqywp"*/}
                {/*                           className="join-btn text-brother" id="tagclick">*/}
                {/*                            <i className='fa-brands fa-discord join-discord'></i>*/}
                {/*                            Join Community*/}
                {/*                        </a>*/}
                {/*                    </Shake>*/}
                {/*                </div>*/}

                {/*                /!*<div className='w-100 join-area mt-3 d-flex justify-content-center'>*!/*/}
                {/*                /!*    <a href="https://raritysniper.com/nft-drops-calendar"*!/*/}
                {/*                /!*       className="raritysniper-btn text-brother" id="rarityClick">*!/*/}
                {/*                /!*        Rarity Sniper*!/*/}
                {/*                /!*    </a>*!/*/}
                {/*                /!*</div>*!/*/}
                {/*            </div>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </section>
    )
}

export default Home