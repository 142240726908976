import {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import contract from '../ExampleToken.json';
import {ethers} from 'ethers';
import toast, {Toaster} from 'react-hot-toast';
import {Spinner} from 'react-bootstrap';
import {ConnectButton, connectorsForWallets, wallet} from '@rainbow-me/rainbowkit';
import {
    getCurrentWalletConnected,
} from "../util/interact.js";
import whitelistAddresses from '../whitelist.json';
import '@rainbow-me/rainbowkit/dist/index.css';
import {MetaMaskConnector} from 'wagmi/connectors/metaMask'
import {
    getDefaultWallets,
    RainbowKitProvider
} from '@rainbow-me/rainbowkit';
import {
    chain,
    configureChains,
    createClient,
    WagmiConfig,
} from 'wagmi';
import {alchemyProvider} from 'wagmi/providers/alchemy';
import {publicProvider} from 'wagmi/providers/public';
import {MerkleTree} from 'merkletreejs'
import keccak256 from 'keccak256'
import {isMobile} from 'react-device-detect';

require('dotenv').config()

let walletChain;
switch (process.env.REACT_APP_CONTRACT_CHAIN) {
    case "2":
        walletChain = chain.kovan;
        break;
    case "3":
        walletChain = chain.ropsten;
        break;
    case "4":
        walletChain = chain.rinkeby;
        break;
    case "137":
        walletChain = chain.polygon;
        break;
    case "80001":
        walletChain = chain.polygonMumbai;
        break;
    default:
        walletChain = chain.mainnet;
        break;
}

const {chains, provider} = configureChains(
    [walletChain],
    [
        alchemyProvider({alchemyId: process.env.ALCHEMY_ID}),
        publicProvider()
    ]
);

const {connectors} = getDefaultWallets({
    appName: 'My RainbowKit App',
    chains,
});

const mobileConnectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [wallet.metaMask({chains})],
    },
]);

let wagmiClient;
if (isMobile) {
    wagmiClient = createClient({
        autoConnect: true,
        connectors: mobileConnectors(),
        provider
    });
} else {
    wagmiClient = createClient({
        autoConnect: true,
        connectors,
        provider
    });
}

const BigNumber = require('bignumber.js');

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
const abi = contract.abi;
const {ethereum} = window;

const Mint = () => {
    const [count, setCount] = useState(0)
    const [walletAddress, setWallet] = useState("");
    const [network, setNetwork] = useState("")
    const [maxCount, setMaxcount] = useState(0)
    const [publicMintEnabled, setPublicMintStatus] = useState(false)
    const [whitelistMintEnabled, setWhitelistMintStatus] = useState(false)
    const [totalSupply, setTotalSupply] = useState(0)
    const [maxSupply, setMaxSupply] = useState(0)
    const [currentPhase, setCurrentPhase] = useState(0)
    const [price, setPrice] = useState(0)
    const handleMinus = () => {
        if (count == 0) {
            return
        }
        setCount(count - 1)
    }
    const handlePlus = () => {
        if (count == maxCount) {
            toast.error('This wallet can\'t mint more tokens!')
            return
        }
        setCount(count + 1)
    }
    const handleMint = () => {
        if (walletAddress.length > 0) {
            if (whitelistMintEnabled) {
                mintNft_whitelist()
            } else if (publicMintEnabled) {
                mintNft()
            } else {
                toast.error("The sale is paused!")
            }
        }
    }

    function handleMintError(str) {
        if (str.includes('Invalid mint amount!')) {
            toast.error('Either you already claimed the maximum amount, or you entered an invalid number!');
        } else if (str.includes('Max supply exceeded!')) {
            toast.error('The supply is sold out! 🚀');
        } else if (str.includes('Insufficient funds!')
            || str.includes('insufficient funds')) {
            toast.error('Insufficient funds!');
        } else if (str.includes('invalid caller')) {
            toast.error('Initial_script not run yet!');
        } else {
            toast.error(str);
        }
    }

    const mintNft_whitelist = async () => {
        if (ethereum) {
            if (count == 0) {
                toast.error('Please choose a valid amount of tokens!')
                return
            }
            let provider = new ethers.providers.Web3Provider(ethereum);
            let signer = provider.getSigner();
            let nftContract = new ethers.Contract(contractAddress, abi, signer);
            toast('Please wait!', {
                icon: '👏',
            });
            let total = parseFloat(price) * count;
            await nftContract.whitelistMint(count, getProofForAddress(), {value: ethers.utils.parseEther(`${total}`)})
                .then(res => {
                    toast.success(`Transaction pending for whitelist:\n${getBlockExplorer() + res.hash}`)
                })
                .catch((err) => {
                    if (err) {
                        let str = err.toString()
                        if (str.includes('Invalid proof')) {
                            toast.error('You are not on the whitelist!');
                        } else if (str.includes('Address already claimed')) {
                            toast.error('You have already claimed your whitelist mint!');
                        } else if (str.includes('The whitelist sale is not enabled')) {
                            toast.error('The whitelist sale is not enabled!');
                        } else {
                            handleMintError(str);
                        }
                    } else {
                    }
                })

        }
    }
    const mintNft = async () => {
        if (ethereum) {
            if (count == 0) {
                toast.error('Please choose a valid amount of tokens!')
                return
            }
            let provider = new ethers.providers.Web3Provider(ethereum);
            let signer = provider.getSigner();
            let nftContract = new ethers.Contract(contractAddress, abi, signer);
            let total = parseFloat(price) * count;
            toast('Please wait!', {
                icon: '👏',
            });

            await nftContract.mint(count, {value: ethers.utils.parseEther(`${total}`)})
                .then(res => {
                    toast.success(`Transaction pending:\n${getBlockExplorer() + res.hash}`)
                })
                .catch((err) => {
                    if (err) {
                        let str = err.toString()
                        if (str.includes('The contract is paused!')) {
                            toast.error('The sale is currently closed!');
                        } else {
                            handleMintError(str);
                        }
                    } else {
                    }
                })
        }
    }

    function isCorrectChain() {
        return ethereum.networkVersion == process.env.REACT_APP_CONTRACT_CHAIN;
    }

    function getNetworkName(chain) {
        switch (chain) {
            case "1":
                return ('Ethereum Mainnet');
            case "2":
                return ('Kovan Test Network');
            case "3":
                return ('Ropsten Test Network');
            case "4":
                return ('Rinkeby Test Network');
            case "137":
                return ('Polygon Mainnet');
            case "80001":
                return ('Mumbai Test Network');
            default:
                return ('Unknown Network');
        }
    }

    function getBlockExplorer() {
        switch (ethereum.networkVersion) {
            case "1":
                return ('https://etherscan.io/address/');
            case "2":
                return ('https://kovan.etherscan.io/address/');
            case "3":
                return ('https://ropsten.etherscan.io/address/');
            case "4":
                return ('https://rinkeby.etherscan.io/address/');
            case "137":
                return ('https://polygonscan.com/address/');
            case "80001":
                return ('https://mumbai.polygonscan.com/address/');
            default:
                return ('Unknown Network/');
        }
    }

    function getCurrency() {
        switch (ethereum.networkVersion) {
            case "137":
            case "80001":
                return ('MATIC');
            default:
                return ('ETH');
        }
    }

    async function addWalletListener() {
        if (window.ethereum) {
            setNetwork(getNetworkName(ethereum.networkVersion));

            window.ethereum.on("accountsChanged", async (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    if (!isCorrectChain()) {
                        setMaxcount(0)
                        return
                    }
                    let provider = new ethers.providers.Web3Provider(ethereum);
                    let signer = provider.getSigner();
                    let nftContract = new ethers.Contract(contractAddress, abi, signer);
                    let max = await nftContract.currentMaxMintAmountPerTx()
                    setMaxcount(ethers.BigNumber.from(max).toNumber())
                } else {
                    setWallet("");
                }
            });
        }
    }

    const getMerkleTree = () => {
        let leaves = whitelistAddresses.map(addr => keccak256(addr))
        // Create tree
        let merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})
        // let rootHash = merkleTree.getRoot().toString('hex')
        return merkleTree;
    }
    const getProofForAddress = () => {
        return getMerkleTree().getHexProof(keccak256(walletAddress));
    }

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on("chainChanged", () => {
                window.location.reload();
            });
            window.ethereum.on("accountsChanged", () => {
                window.location.reload();
            });
        }
    });
    useEffect(async () => {
        const {address} = await getCurrentWalletConnected();
        setWallet(address);
        await addWalletListener();
        if (!address) {
            document.getElementById("wallet-address").textContent = "Not Connected"
            return;
        }
        if (!isCorrectChain()) return
        let provider = new ethers.providers.Web3Provider(ethereum);
        let signer = provider.getSigner();
        let nftContract = new ethers.Contract(contractAddress, abi, signer);
        let whitelistEnabled = await nftContract.whitelistMintEnabled()
        let publicSaleEnabled = !(await nftContract.paused())
        let currentPhase = ethers.BigNumber.from(await nftContract.currentPhase()).toNumber()
        if (!whitelistEnabled && !publicSaleEnabled && currentPhase > 0)
            currentPhase--;
        let max = await nftContract.currentMaxMintAmountPerTx()
        let totalSupply = await nftContract.totalSupply()
        let maxSupply = 0
        for (let i = currentPhase; i >= 0; i--) {
            maxSupply += ethers.BigNumber.from(await nftContract.supplyLimiters(i)).toNumber()
        }
        let price = await nftContract.currentCost()
        setWhitelistMintStatus(whitelistEnabled)
        setPublicMintStatus(publicSaleEnabled)
        setTotalSupply(totalSupply)
        setMaxSupply(maxSupply)
        setCurrentPhase(currentPhase)
        setMaxcount(ethers.BigNumber.from(max).toNumber())
        setPrice(parseFloat(ethers.utils.formatEther(price)))
    }, []);
    return (
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
                <div className='dobby-landing'>
                    <section id="mint">
                        <img src='graphics/Dobby planet-cropped.svg' className='mint-planet'></img>

                        <div className='mint-body'>
                            <img src='graphics/mobile/mint screen stars.svg' width={550} className='mint-star1'></img>
                            <img src='graphics/mobile/mint screen stars.svg' width={550} className='mint-star2'></img>
                            <Toaster
                                containerStyle={{
                                    top: 160,
                                    left: 20,
                                    bottom: 20,
                                    right: 20,
                                }}
                                toastOptions={{
                                    style: {
                                        wordBreak: 'break-all'
                                    },
                                    success: {
                                        duration: 10000,
                                        theme: {
                                            primary: 'green',
                                            secondary: 'black',
                                        },
                                    },
                                }}
                            />
                            <div className='mint-field'>
                                <div className='text-center'>
                                    <img src='graphics/Logo.svg' className='mint-logo'/>
                                </div>

                                <div className='w-100 mint-alert text-center'>
                                    {walletAddress.length > 0 ? (
                                            isCorrectChain() ?
                                                <p className='text-sans2 text-white mb-0'>Current network: {network}</p>
                                                :
                                                <p className='text-sans2 text-white mb-0'>{"Wrong network. Connect to " + getNetworkName(process.env.REACT_APP_CONTRACT_CHAIN) + "!"}</p>
                                        )
                                        :
                                        isMobile ?
                                            <p className='text-sans2 text-white mb-0'>Your wallet is not connected
                                                yet.<br/>If minting does not work, try to open the page<br/>in the
                                                Metamask Browser as described <a
                                                    href="https://metamask.zendesk.com/hc/en-us/articles/6356387482523-How-to-use-the-MetaMask-Mobile-Browser">here</a>
                                            </p>
                                            :
                                            <p className='text-sans2 text-white mb-0'>Your wallet is not connected
                                                yet</p>
                                    }

                                </div>
                                <div className='w-100 mint-info text-center mt-3'>
                                    <div className='address-info'>
                                        <p className='text-sans2 text-bold text-yellow mb-0'>Wallet Address</p>
                                        <p className='text-sans2 text-white wallet-address'
                                           id='wallet-address'>{walletAddress}</p>
                                    </div>
                                    <div className='d-flex'>
                                        {/*<div className='w-100 mint-supply'>*/}
                                        {/*    {*/}
                                        {/*        maxSupply > 0 ? (*/}
                                        {/*                <>*/}
                                        {/*                    <p className='text-sans2 text-bold text-yellow mb-0 mt-3'>{'Supply (Phase ' + (currentPhase + 1) + ')'}</p>*/}
                                        {/*                    <p className='text-sans2 text-white'>{Math.floor((maxSupply - totalSupply) / maxSupply * 100) + '% left'}</p>*/}
                                        {/*                </>*/}
                                        {/*            )*/}
                                        {/*            : (*/}
                                        {/*                <>*/}
                                        {/*                    <p className='text-sans2 text-bold text-yellow mb-0 mt-3'>{'Supply'}</p>*/}
                                        {/*                    <p className='text-sans2 text-white'>Not Connected</p>*/}
                                        {/*                </>*/}
                                        {/*            )*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                        <div className='w-100 mint-sale'>
                                            <p className='text-sans2 text-bold text-yellow mb-0 mt-3'>Sale Status</p>
                                            <p className='text-sans2 text-white'>{
                                                walletAddress.length == 0 ?
                                                    'Not Connected' :
                                                    (
                                                        whitelistMintEnabled ?
                                                            'Whitelist Open' :
                                                            (
                                                                publicMintEnabled ? 'Open' : 'Closed'
                                                            )
                                                    )
                                            }</p>
                                        </div>
                                    </div>
                                </div>
                                {walletAddress.length > 0 ?
                                    <div className='w-100 mint-target text-center mt-3'>
                                        <div className='mint-img'>
                                            <img src='graphics/unrevealed.gif' width={150} height={150}
                                                 className="collection-img"/>
                                        </div>
                                        <div className='mint-price'>
                                            <p className='text-white text-sans2 mb-0'>Total
                                                price: {count > 0 ? ethers.utils.commify((price * count).toFixed(5)) : '0'} {getCurrency()}</p>
                                        </div>
                                        {
                                            maxCount > 0 ?
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div className='mint-minus' onClick={handleMinus}>-</div>
                                                    <div className='mint-count text-white'>{count}</div>
                                                    <div className='mint-plus' onClick={handlePlus}>+</div>
                                                    <div className='mint-button text-brother text-white'
                                                         onClick={handleMint}>Mint
                                                    </div>
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center mt-1'>
                                                    <Spinner animation="border" variant="primary"/>
                                                </div>
                                        }
                                    </div>
                                    :

                                    <div className='w-100  text-center mt-3'>
                                        <div className='mint-img'>
                                            <img src='graphics/unrevealed.gif' width={150} height={150}
                                                 className="collection-img"/>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center wallet-btn'>
                                            <ConnectButton/>
                                        </div>
                                        {/* <div className='text-brother mint-connect text-white' onClick={openConnectModal}>Connect Wallet</div>*/}
                                    </div>
                                }

                                <div className='text-center mt-5 w-100' style={{paddingBottom: 50}}>
                                    <Link to="/" className="back-btn text-brother">BACK</Link>
                                </div>

                            </div>

                        </div>
                    </section>
                </div>
            </RainbowKitProvider>
        </WagmiConfig>
    )
}

export default Mint