// import { Container } from "react-bootstrap";
import {Routes, Route} from "react-router-dom";
// import { lazy, Suspense } from 'react';
// Layout
import Layout from "./layout/Layout";

// pages
import Home from "./pages/Home";
import Mint from "./pages/Mint";
import Roadmap from "./pages/Roadmap";
import NotFound from "./pages/NotFound";

require('dotenv').config()

var mintRoute;

if (process.env.REACT_APP_MINT_ENABLED == "true")
    mintRoute = <Route path="/mint/" element={<Mint/>}/>;

// const Home = lazy(() => import('./pages/Home'));

const App = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home/>} exact/>
                {mintRoute}
                {/*<Route path="/roadmap/" element={<Roadmap/>}/>*/}
                <Route element={<NotFound/>}/>
            </Routes>
        </Layout>
    );
};

export default App;
