import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";

require('dotenv').config()

var mintEnabled = process.env.REACT_APP_MINT_ENABLED == "true";

const Menu = () => {

    let navigate = useNavigate();
    const navigateMintingPage = () => {
        let path = `/mint`;
        navigate(path);
    }

    const handleMenuLeft = () => {
        const buttons = document.getElementsByClassName('active-trans');
        const buttons1 = document.getElementsByClassName('active-trans1');
        const field = document.getElementsByClassName('active-trans2');
        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].classList.contains('active'))
                buttons[i].classList.remove('active')
            else
                buttons[i].classList.add('active')
        }
        for (let i = 0; i < buttons1.length; i++) {
            if (buttons1[i].classList.contains('active1'))
                buttons1[i].classList.remove('active1')
            else
                buttons1[i].classList.add('active1')
        }
        for (let i = 0; i < field.length; i++) {
            if (field[i].classList.contains('active2'))
                field[i].classList.remove('active2')
            else
                field[i].classList.add('active2')
        }
    }
    const handleLink = () => {
        const buttons = document.getElementsByClassName('active-trans');
        const buttons1 = document.getElementsByClassName('active-trans1');
        const field = document.getElementsByClassName('active-trans2');
        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].classList.contains('active'))
                buttons[i].classList.remove('active')
        }
        for (let i = 0; i < buttons1.length; i++) {
            if (buttons1[i].classList.contains('active1'))
                buttons1[i].classList.remove('active1')
        }
        for (let i = 0; i < field.length; i++) {
            if (field[i].classList.contains('active2'))
                field[i].classList.remove('active2')
        }
    }
    return (
        <div>
            <div className='dobby-landing header'>
                <div className='d-flex justify-content-between align-items-center header-field'>
                    <div className="d-flex align-items-center">
                        <div className="bar-mobile" onClick={handleMenuLeft}><i className="fa fa-bars ml-2"></i></div>
                        <Link to="/">
                            <img src='/graphics/Logo.svg' className='logo'/>
                        </Link>
                    </div>
                    <div className=' header-btn-field'>
                        {
                            mintEnabled ?
                                <button className="mint-btn text-brother" onClick={navigateMintingPage}>MINT</button>
                                : <button className="mint-btn-disabled text-brother">MINT</button>
                        }
                        <a href="https://opensea.io/collection/dobbydinos" className="opensea-btn mr-1"></a>
                        {/*<a href="https://twitter.com/bydobbyland" className="twitter-btn mr-1"></a>*/}
                        {/*<a href="https://discord.com/invite/5vXP2wqywp" className="discord-btn" id="tagclick"></a>*/}

                    </div>
                </div>
                {/* mobile -field */}

                <div className="round-btn-cover">
                    <button className="opensea-btn-mobile active-trans"></button>
                </div>
                {/*<div className="round-btn-cover" onClick={handleLink}><a href="https://twitter.com/bydobbyland"*/}
                {/*                                                         className="twitter-btn-mobile mr-1 active-trans"></a>*/}
                {/*</div>*/}
                {/*<div className="round-btn-cover" onClick={handleLink}><a href="https://discord.com/invite/5vXP2wqywp"*/}
                {/*                                                         className="discord-btn-mobile active-trans"*/}
                {/*                                                         id="tagclick"></a></div>*/}

                {/*<div onClick={handleLink}><Link to="/"*/}
                {/*                                className="home-btn-mobile  text-brother active-trans1">HOME</Link>*/}
                {/*</div>*/}
                {/*<div onClick={handleLink}><Link to="/roadmap"*/}
                {/*                                className="roadmap-btn-mobile  text-brother active-trans1">ROADMAP</Link>*/}
                {/*</div>*/}
                {/*/!* <div className="doobcoin-btn-mobile mr-1 text-brother active-trans-right ">DOOBCOIN</div> *!/*/}
                {/*<div className="breeding-btn-mobile mr-1 text-brother active-trans1 text-disable">BREEDING</div>*/}
                {/*<div className="shop-btn-mobile mr-1 text-brother active-trans1 text-disable">SHOP</div>*/}
                {/*<div className="mobile-field active-trans2"></div>*/}

                {/* mobile field end */}
            </div>
            {/*<div className="dobby-landing dobby-navbar">*/}
            {/*    <div className="navbar-field">*/}
            {/*        <div className="nav-item"><Link to="/" className="text-brother dobby-nav-item">HOME</Link></div>*/}
            {/*        <div className="nav-item"><Link to="/roadmap" className="text-brother dobby-nav-item">ROADMAP</Link>*/}
            {/*        </div>*/}
            {/*        <div className="nav-item"><Link to="/roadmap"*/}
            {/*                                        className="text-brother dobby-nav-item-disabled">BREEDING</Link>*/}
            {/*        </div>*/}
            {/*        <div className="nav-item"><Link to="/roadmap"*/}
            {/*                                        className="text-brother dobby-nav-item-disabled">SHOP</Link></div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default Menu;
